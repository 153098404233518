import { Action, Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { auth, RoleId } from '@auth';
import { modals } from '@modals';
import { Invite, Organization } from '@models';
import { alert } from '@services/alert';
import { clipboard } from '@services/clipboard';
import { createOptions } from '@utils/select-options';
import { store } from '@store';

import TableCellInviteStatus from '@components/TableCells/TableCellInviteStatus.vue';
import TableCellListItems from '@components/TableCells/TableCellListItems.vue';

import { faCopy } from '@icons/solid/faCopy';
import { faEnvelope } from '@icons/solid/faEnvelope';
import { faAddressCard } from '@icons/solid/faAddressCard';

const columns: TablePanel.Column<Invite>[] = [
  {
    key: 'createdAt',
    label: 'Created',
    type: 'date',
    value: 'createdAt',
  },
  {
    key: 'email',
    label: 'Email',
    value: 'email',
  },
  {
    key: 'institution',
    label: 'Institution',
    value: ({ organization }) => organization?.name,
    placeholder: 'N/A',
    filterByFormatted: true,
    sortByFormatted: true,
  },
  {
    key: 'course',
    label: 'Course',
    value: ({ course }) => course?.name,
    placeholder: 'N/A',
  },
  // {
  //   key: 'resellerName',
  //   label: 'Reseller',
  //   value: ({ reseller }) => reseller?.name,
  //   placeholder: 'N/A',
  //   hidden: ({ selectedRole }) => selectedRole?.roleId !== RoleId.LasAdmin,
  // },
  {
    key: 'roleName',
    label: 'Role',
    value: 'roleName',
  },
  {
    key: 'status',
    label: 'Status',
    component: TableCellInviteStatus,
    value: (item) => item,
  },
  {
    key: 'expirationDate',
    label: 'Invite Expires',
    type: 'date',
    value: 'expirationDate',
  },
  {
    key: 'expirationDays',
    label: 'License Expiration Days',
    value: 'expirationDays',
  },
  {
    key: 'receiver',
    label: 'User Id',
    value: ({ receiver }) => `${receiver ? receiver.id : 'None'}`,
    placeholder: 'N/A',
    hidden: ({ selectedRole }) => selectedRole?.roleId !== RoleId.LasAdmin,
  },
  {
    key: 'products',
    label: 'Products',
    value: ({ products }) => {
      if (!products?.length) return ['None'];

      return products;
    },
    component: TableCellListItems,
    placeholder: 'N/A',
    hidden: ({ selectedRole }) => selectedRole?.roleId !== RoleId.LasAdmin,
  },
];

const operations: TablePanel.Row.Operation<Invite>[] = [
  {
    label: 'Get Invite Link',
    icon: faCopy,
    fn: ({ id }) => {
      void clipboard.copy(`${location.origin}/register/${id}`);
    },
  },
  {
    label: 'Resend Invite',
    icon: faEnvelope,
    fn: ({ organization, id }) => {
      if (!organization?.id) return;
      void resendInvite(organization?.id, id);
    },
    hidden: ({ used, organization }) => {
      return used || !organization?.id;
    },
  },
  {
    label: 'View User',
    icon: faAddressCard,
    fn: ({ receiver }) => {
      if (!receiver) return;
      void modals.user.details({ user: receiver });
    },
    hidden() {
      return !auth.isActiveRole(RoleId.LasAdmin);
    },
  },
];

const filterFields = ['email', 'createdAt', 'institution'];

const specialFilters = [
  {
    type: 'dropdown',
    label: 'Accepted',
    options: [
      { value: '--', text: '--' },
      { value: true, text: 'Yes' },
      { value: false, text: 'No' },
    ],
    handler: (item: Invite, choice: unknown) => {
      return item.accepted === choice;
    },
  },
  {
    type: 'dropdown',
    label: 'Role',
    options: [
      { value: '--', text: '--' },
      { value: 'Subscriber', text: 'Subscriber' },
      { value: 'Independent Operator', text: 'Independent Operator' },
      { value: 'Student', text: 'Student' },
      { value: 'Instructor', text: 'Instructor' },
      { value: 'Institution Admin', text: 'Institution Admin' },
    ],
    handler: (item: Invite, choice: Invite['roleName']) => {
      return item.roleName === choice;
    },
  },
];

const filterParams: TablePanel.FilterParam[] = [
  {
    key: 'organizationId',
    label: 'Organization',
    options: async () => {
      const items = await store.dispatch('organizations/list');

      return createOptions.organizations(items);
    },
  },
];

@Module({ namespaced: true })
export class InvitesTablePanel
  extends TablePanel<Invite>
  implements TablePanel.Props<Invite>
{
  onPageChanged?: TablePanel.OnPageChangedCallback<Invite>;
  readonly module = 'invites';
  readonly loadAction = 'list';
  readonly loadPageAction = 'loadPage';
  readonly pk = 'id';
  readonly label = 'INVITES';
  readonly columns = columns;
  readonly operations = operations;
  readonly filterFields = filterFields;
  readonly deleteAction = 'deleteInvite';
  readonly tableSortType = 'createdAt';
  readonly tableSortReverse = true;
  readonly specialFilters = specialFilters;

  readonly filterParams = filterParams;

  get enabled() {
    return this.isActiveRole(4, 5, 7, 9);
  }

  get progressive() {
    return this.isActiveRole(RoleId.LasAdmin);
  }

  /** ... */
  get tableMenu() {
    const roleId = this.activeUser.selectedRole?.roleId;

    const items: TablePanel.MenuItem[] = [];

    if (roleId === RoleId.Reseller || roleId === RoleId.LasAdmin) {
      items.push({
        key: 'InviteIndependentOperator',
        label: 'Invite Independent Operator(s)',
        click: () => void modals.user.inviteIndieOperator(),
      });
    }

    if (
      roleId === RoleId.Instructor ||
      roleId === RoleId.InstitutionAdmin ||
      roleId === RoleId.LasAdmin
    ) {
      items.push({
        key: 'inviteStudent',
        label: 'Invite Student(s)',
        click: () => void modals.user.inviteStudent(),
      });
    }

    if (
      roleId === RoleId.InstitutionAdmin ||
      roleId === RoleId.Reseller ||
      roleId === RoleId.LasAdmin
    ) {
      items.push({
        key: 'inviteAdminOrInstructor',
        label: 'Invite Admin/Instructor(s)',
        click: () => void modals.user.invite(),
      });
    }

    if (roleId === RoleId.LasAdmin) {
      // items.push({
      //   key: 'InvliteResellerUsers',
      //   label: 'Invite Reseller Users',
      //   click: () => void modals.user.inviteReseller(),
      // });

      items.push({
        key: 'InviteReviewerUsers',
        label: 'Invite Reviewer(s)',
        click: () => void modals.user.inviteReviewers(),
      });

      items.push({
        key: 'InviteLasAdmin',
        label: 'Invite LAS Admin(s)',
        click: () => void modals.user.inviteLasAdmin(),
      });
    }

    return items;
  }

  @Action
  async deleteInvite({ items }: InvitesTablePanel.DeleteItemOptions) {
    await modals.confirm.deleteItems({ itemType: 'invites', items });

    void this.context.dispatch('load');
  }
}

async function resendInvite(
  organizationId: Organization['id'],
  inviteId: Invite['id'],
) {
  try {
    await store.dispatch('invites/resend', {
      organizationId,
      invites: [inviteId],
    });
  } catch (err) {
    return alert.error(err);
  }

  alert.success('Invite Resent');
}

export namespace InvitesTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: Invite[];
  }

  /** ... */
  export interface ResendItemOptions {
    invite: Invite;
  }
}
