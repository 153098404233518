<script lang="ts">
import { computed } from 'vue';

const TEXT_COLOR_GRAY = '#363b47';
const BG_COLOR_GRAY = '#9fa5b3';

const TEXT_COLOR_GREEN = '#004546';
const BG_COLOR_GREEN = '#37da88';

const TEXT_COLOR_YELLOW = '#5f4000';
const BG_COLOR_YELLOW = '#ffd800';

const TEXT_COLOR_RED = '#5d0029';
const BG_COLOR_RED = '#ff4f80';

interface StatusDisplayConfig {
  text: string;
  color: string;
  backgroundColor: string;
}

export interface StatusConfig {
  [key: string]: StatusDisplayConfig;
}

// Default status configs that can be overridden via props
export const DEFAULT_STATUS_CONFIGS: StatusConfig = {
  QUEUED: {
    text: 'Queued',
    color: TEXT_COLOR_GRAY,
    backgroundColor: BG_COLOR_GRAY,
  },
  PROCESSING: {
    text: 'Processing',
    color: TEXT_COLOR_YELLOW,
    backgroundColor: BG_COLOR_YELLOW,
  },
  COMPLETED: {
    text: 'Completed',
    color: TEXT_COLOR_GREEN,
    backgroundColor: BG_COLOR_GREEN,
  },
  ERROR: {
    text: 'Error',
    color: TEXT_COLOR_RED,
    backgroundColor: BG_COLOR_RED,
  },
};
</script>

<script setup lang="ts">
export interface Props {
  status: string;
  statusConfigs?: StatusConfig;
  fullwidth?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  statusConfigs: () => DEFAULT_STATUS_CONFIGS,
  fullwidth: false,
});

const displayConfig = computed(() => {
  return props.statusConfigs[props.status];
});

const classList = computed(() => {
  const classList: string[] = ['label-status'];
  if (props.fullwidth) classList.push('fullwidth');
  return classList;
});

const style = computed(() => ({
  color: displayConfig.value.color,
  backgroundColor: displayConfig.value.backgroundColor,
}));
</script>

<template>
  <span :class="classList" :style="style">
    {{ displayConfig.text }}
  </span>
</template>

<style scoped lang="scss">
.label-status {
  display: inline-block;
  padding: 4px 16px;
  font-weight: 400;
  text-align: center;
  border-radius: 10px;

  &.fullwidth {
    width: 170px;
  }
}
</style> 
