<script setup lang="ts">
/* eslint-disable @typescript-eslint/non-nullable-type-assertion-style */

import { computed, inject } from 'vue';
import type { TablePanel } from 'table-panel';

import { IconDefinition } from '@icons/core';

import { faPlus } from '@icons/regular/faPlus';
import { faTrashCan } from '@icons/regular/faTrashCan';
import { faSync } from '@icons/regular/faSync';
import { faEllipsisV } from '@icons/regular/faEllipsisV';
import { faExpandAlt } from '@icons/solid/faExpandAlt';
import { faCompressAlt } from '@icons/solid/faCompressAlt';
import { faFilter } from '@icons/regular/faFilter';
import { faInfo } from '@icons/regular/faInfo';

import { TableRefs, DataItem } from './table';
import { useTableMenu } from './utils';

export interface FilterEvent {
  key: string;
  value: boolean;
}

/** `PanelSelectionManager` component emits. */
export interface Emits {
  (event: 'minimize' | 'maximize'): void;
  (event: 'filter', value: FilterEvent): void;
}

// type MenuButton = TablePanel.MenuItem & { type: 'button' };

interface MenuButton {
  key: string;
  type: 'button';
  label: string;
  icon?: IconDefinition;
  disabled: boolean;
  click: () => void;
}

interface MenuSeparator {
  key: string;
  type: 'separator';
}

const emit = defineEmits<Emits>();

const path = inject('$path') as TableRefs['path'];
const tableLoading = inject('$tableLoading') as TableRefs['tableLoading'];
const createAction = inject('$createAction') as TableRefs.Action;
const deleteAction = inject('$deleteAction') as TableRefs.Action;
const queryText = inject('$queryText') as TableRefs['queryText'];
const selection = inject('$selection') as TableRefs.Selection;
const fullscreen = inject('$fullscreen') as TableRefs['fullscreen'];
const filterTags = inject('$tableFilterTags') as TableRefs['tableFilterTags'];
const filterParams = inject('$tableFilterParams') as TableRefs['tableFilterParams'];
const tableFilters = inject('$tableFilters') as TableRefs['tableFilters'];
const updateSearch = inject('$updateSearch') as TableRefs['updateSearch'];
const refreshItems = inject('$refreshItems') as TableRefs['refreshItems'];
const createItem = inject('$createItem') as TableRefs['createItem'];
const deleteItem = inject('$deleteItem') as TableRefs['deleteItem'];
const infoMessage = inject('$infoMessage') as TableRefs['infoMessage'];

const tableMenu = useTableMenu(path.value);

/** ... */
const hasFilterTags = computed(() => !!Object.keys(filterTags).length);
/** ... */
const hasFilterParams = computed(() => !!Object.keys(filterParams).length);
/** ... */
const hasSelection = computed(() => !!selection.value.length);

/** Table "search" filter text. */
const search = computed({
  get: () => queryText.value ?? '',
  set: updateSearch,
});

/** Table "additional options" menu items. */
const menuItems = computed(() => {
  if (!tableMenu.value.length) return null;

  const items: (MenuButton | MenuSeparator)[] = [];

  for (const [index, item] of tableMenu.value.entries()) {
    if ('type' in item) {
      items.push({ key: `item-${index}`, type: 'separator' });
    } else {
      items.push(createMenuItemButton(item));
    }
  }

  return items;
});

function createMenuItemButton(item: TablePanel.MenuItemButton<DataItem>) {
  let disabled;

  if (typeof item.disabled === 'boolean') {
    disabled = item.disabled;
  } else if (typeof item.disabled === 'function') {
    disabled = item.disabled({ selection: selection.value.map((o) => o.item) });
  } else {
    disabled = false;
  }

  const click = () => {
    void item.click({ selection: selection.value.map((o) => o.item) });
  };

  return { ...item, type: 'button', disabled, click } as MenuButton;
}

function clearSearch() {
  search.value = '';
}

function toggleFullscreenMode() {
  emit(fullscreen.value ? 'minimize' : 'maximize');
}

// Add a computed property to filter visible params
const visibleFilterParams = computed(() => {
  if (!filterParams) return {};

  return Object.entries(filterParams).reduce((acc, [key, param]) => {
    if (!param.hidden || !param.hidden(path.value.user)) {
      acc[key] = param;
    }
    return acc;
  }, {} as typeof filterParams);
});

const hasVisibleFilterParams = computed(() => 
  Object.keys(visibleFilterParams.value).length > 0
);

// Add a computed property to determine if we should show the filter params section
const showFilterParams = computed(() => {
  return Object.keys(visibleFilterParams.value || {}).length > 0;
});
</script>

<template>
  <b-button-toolbar class="table-toolbar" :disabled="tableLoading" justify>
    <div class="toolbar-group">
      <!-- #region Search Bar  -->

      <b-input-group class="flex-grow-1 mr-2">
        <b-form-input
          v-model.trim="search"
          debounce="500"
          placeholder="Search Table"
        />

        <b-input-group-append>
          <b-button :disabled="!search" @click="clearSearch">Clear</b-button>
        </b-input-group-append>
      </b-input-group>

      <!-- #endregion Search Bar  -->

      <!-- #region Filters Dropdown -->

      <b-dropdown
        v-if="hasFilterTags || hasVisibleFilterParams"
        variant="secondary"
        class="mr-2"
        right
      >
        <template #button-content> <Icon :icon="faFilter" /> Filters </template>

        <b-dropdown-form>
          <template v-if="hasFilterTags">
            <b-form-checkbox
              v-for="(item, key) in filterTags"
              :key="key"
              v-model="item.enabled"
              class="my-2"
            >
              {{ item.label }}
            </b-form-checkbox>

            <b-dropdown-divider v-if="hasVisibleFilterParams" class="mt-3 mb-3" />
          </template>

          <template v-for="(item, key) in visibleFilterParams">
            <b-input-group
              :key="key"
              :prepend="item.label"
              class="filter-input-group"
            >
              <b-form-input
                v-if="!item.options"
                v-model="item.value"
                debounce="500"
              />

              <b-form-select
                v-else
                v-model="item.value"
                :options="item.options"
              >
                <template #first>
                  <b-form-select-option :value="null">
                    No Selection
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-input-group>
          </template>
        </b-dropdown-form>
      </b-dropdown>

      <!-- #endregion Filters Dropdown -->

      <!-- #region Action Buttons -->

      <b-button-group class="mr-2">
        <!-- Create Item Button-->

        <b-button
          v-if="createAction"
          v-b-tooltip="'Create'"
          @click="createItem"
        >
          <Icon :icon="faPlus" />
        </b-button>

        <!-- Delete Item Button-->

        <b-button
          v-if="deleteAction"
          v-b-tooltip="'Delete'"
          style="color: var(--danger)"
          :disabled="!hasSelection"
          @click="deleteItem()"
        >
          <Icon :icon="faTrashCan" />
        </b-button>

        <!-- Refresh Items Button-->

        <b-button v-b-tooltip="'Refresh'" @click="refreshItems">
          <Icon :icon="faSync" />
        </b-button>

        <!-- Additional Options Dropdown-->

        <b-dropdown
          v-if="menuItems"
          v-b-tooltip="'Additional Options'"
          dropleft
          no-caret
        >
          <template #button-content>
            <Icon :icon="faEllipsisV" />
          </template>

          <template v-for="item in menuItems">
            <template v-if="item.type === 'separator'">
              <b-dropdown-divider :key="item.key" />
            </template>

            <template v-else>
              <b-dropdown-item-button
                :key="item.key"
                :disabled="item.disabled"
                @click="item.click()"
              >
                <span v-if="item.icon" class="dropdown-icon-wrapper">
                  <Icon :icon="item.icon" />
                </span>
                <span class="dropdown-text-wrapper">{{ item.label }}</span>
              </b-dropdown-item-button>
            </template>
          </template>
        </b-dropdown>
      </b-button-group>

      <!-- Information Tooltip -->
      <b-button-group v-if="infoMessage" class="ml-2">
        <b-button v-b-tooltip.left="infoMessage">
          <Icon :icon="faInfo" />
        </b-button>
      </b-button-group>

      <b-button-group>
        <b-button v-b-tooltip="'Fullscreen'" @click="toggleFullscreenMode">
          <Icon :icon="fullscreen ? faCompressAlt : faExpandAlt" />
        </b-button>
      </b-button-group>

      <!-- #endregion Action Buttons -->
    </div>

    <div v-if="tableFilters.length" class="toolbar-group">
      <div
        v-for="filter in tableFilters"
        :key="filter.label"
        class="toolbar-filter"
      >
        <!-- Toggle Types -->

        <div v-if="filter.type === 'toggle'">
          <b-form-checkbox v-model="filter.enabled">
            {{ filter.label }}
          </b-form-checkbox>
        </div>

        <!-- Date Range Types -->

        <div v-else-if="filter.type === 'date-range'">
          <!-- TODO Add date range support fields -->
          {{ filter.label }}
        </div>

        <!-- Dropdown Types -->
        <div
          v-else-if="filter.type === 'dropdown'"
          class="d-flex align-items-center"
        >
          <b-form-group class="mr-2" :label="filter.label">
            <b-form-select
              v-model="filter.choice"
              :options="filter.options ?? []"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>
                  -- choose option --
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-button :disabled="!filter.choice" @click="filter.choice = null">
            Clear
          </b-button>
        </div>
      </div>
    </div>
  </b-button-toolbar>
</template>

<style scoped lang="scss">
.table-toolbar {
  display: flex;
  flex-direction: column;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.toolbar-group {
  padding: 1.5rem !important;
  display: flex;
  border-bottom: 1px solid var(--border-color) !important;
}

.filter-input-group {
  flex-wrap: nowrap !important;

  > input,
  > select {
    width: 300px !important;
  }
}

.table-toolbar:deep(.toolbar-filter) {
  &:not(:last-child) {
    margin-right: 1rem;
  }

  .form-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;

    legend {
      float: left;
      align-self: center;
      margin-right: 10px;
      padding: 0;
      width: auto;
    }
  }
}
</style>
