var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "table-cell-status" },
    [
      _c(_setup.LabelStatus, {
        attrs: {
          status: _setup.status,
          "status-configs": _vm.statusConfigs,
          fullwidth: _vm.fullwidth,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }