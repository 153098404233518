import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { RoleId } from '@auth';
import { OfflineModeJob } from '@models';
import { store } from '@store';
import { createOptions } from '@utils/select-options';

import { faSync } from '@icons/solid/faSync';

const columns: TablePanel.Column<OfflineModeJob>[] = [
    {
        key: 'id',
        label: 'ID',
        value: 'id',
    },
    {
        key: 'createdAt',
        label: 'Created',
        type: 'dateTime',
        value: 'createdAt',
    },
    {
        key: 'organization',
        label: 'Organization',
        value: (job) => job.organization.name,
        hidden: ({ selectedRole }) => selectedRole?.roleId !== RoleId.LasAdmin,
    },
    {
        key: 'status',
        label: 'Status',
        value: 'status',
        component: 'TableCellStatus',
    },
    {
        key: 'currentCount',
        label: 'Current Count',
        value: 'currentCount',
    },
    {
        key: 'progress',
        label: 'Progress',
        value: 'progress',
    },
];

const filterFields = ['status', 'organization.name'];

const filterParams: TablePanel.FilterParam[] = [
    {
        key: 'organizationId',
        label: 'Organization',
        options: async () => {
            if (store.state.me.selectedRole?.roleId !== RoleId.LasAdmin) return [];

            const items = await store.dispatch('organizations/list');
            return createOptions.organizations(items);
        },
        hidden: () => store.state.me.selectedRole?.roleId !== RoleId.LasAdmin,
    },
];

// TODO: implement retry operation when we have the API endpoint
// const operations: TablePanel.Row.Operation<OfflineModeJob>[] = [
//     {
//         label: 'Retry',
//         icon: faSync,
//     }
// ];

@Module({ namespaced: true })
export class OfflineModeJobsTablePanel
    extends TablePanel<OfflineModeJob>
    implements TablePanel.Props<OfflineModeJob> {
    readonly module = 'offlineModeJobs';
    readonly loadAction = 'list';
    readonly loadPageAction = 'loadPage';
    readonly pk = 'id';
    readonly label = 'OFFLINE MODE JOBS';
    readonly columns = columns;
    readonly filterFields = filterFields;
    readonly filterParams = filterParams;
    readonly tableSortType = 'createdAt';
    readonly tableSortReverse = false;
    readonly progressive = true;

    get enabled() {
        if (this.isActiveRole(RoleId.LasAdmin)) return true;
        return false;
    }

    get infoMessage() {
        if (this.isActiveRole(RoleId.LasAdmin)) {
            return 'Please select an organization from the filters dropdown to view offline mode jobs';
        }
        return null;
    }

    @Action
    async load() {
        const { selectedRole } = this.activeUser;

        // For LAS Admins, wait for organization selection
        if (selectedRole?.roleId === RoleId.LasAdmin) {
            this.context.commit('offlineModeJobs/CLEAR', null, { root: true });
            return;
        }
    }

    @Action
    async loadPage(payload: { filter: { params: { organizationId: string } } }) {
        return await this.context.dispatch('offlineModeJobs/loadPage', payload, { root: true });
    }
} 
