<template>
  <Transition appear>
    <div v-if="display" id="privacy-policy-update-notice">
      <div class="notice-content">
        <p>
          Hey! Just letting you know we updated our Privacy Policy and Terms of Service. You can
          check them out here
          <RouterLink to="/privacy-policy">Privacy Policy</RouterLink> & <RouterLink to="/terms-and-conditions">Terms of Service</RouterLink>. If you
          keep using zephyr-sim.com and the Zephyr Simulator after March 1, 2025, you're letting us
          know that you've read and agreed to these updates.
        </p>

        <b-button variant="primary" @click="dismiss">Dismiss</b-button>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref } from '@vue';

import { ls } from '@services/ls';

/** Name of key to be used to track user acknowlegment in local storage. */
const PPU_KEY = '03012025ZephyrPrivacyPolicyUpdateNotificationDismissed';

/**
 * when we're ready to re-enable privacy policy update notice, comment
 * back in the display variable with "ref(ls.get())" and comment out
 * the variable display = false;
 */
const display = ref(ls.get(PPU_KEY) !== 'true');
// const display = false;

function dismiss() {
  ls.set(PPU_KEY, 'true');

  display.value = false;
}
</script>

<script lang="ts">
export default { name: 'PrivacyPolicyUpdatedNotice' };
</script>

<style lang="scss" scoped>
#privacy-policy-update-notice {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  margin-right: 1rem;
  border-radius: 1rem;
  display: flex;
  background-color: rgb(25, 27, 33);
  color: rgb(222, 222, 222);
  justify-content: center;
  padding: 30px 20px;
  z-index: 100;
  box-shadow: var(--panel-box-shadow);
  transform: translateY(0);
  transition: transform 0.25s;
  max-width: 500px;

  @include v-from {
    transform: translateY(100%);
  }
}

.notice-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 1000px;
  text-align: center;

  a {
    color: var(--blue);
    font-weight: 500;
  }

  p {
    margin-bottom: 0;
  }

  .btn {
    margin-top: 0.5rem;
  }
}
</style>
