import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { ApiKey } from '@models';
import { modals } from '@modals';
import { alert } from '@services/alert';
import { store } from '@client/store';

import { faEdit } from '@icons/solid/faEdit';
import { faEye } from '@icons/solid/faEye';
import { faTrash } from '@icons/solid/faTrash';
import { faBan } from '@icons/solid/faBan';
import { api } from '@client/api';
/** ... */
const columns: TablePanel.Column<ApiKey>[] = [
  {
    key: 'createdAt',
    label: 'Created',
    type: 'dateTime',
    value: 'createdAt',
  },
  {
    key: 'name',
    label: 'Name',
    value: 'name',
  },
  { key: 'id', label: 'ID', value: 'id' },
  { key: 'issuedDate', label: 'Issued', value: 'issuedDate' },
  { key: 'expirationDate', label: 'Expires', value: 'expirationDate' },
  { key: 'active', label: 'Active', value: 'active' },
  { key: 'organization', label: 'Organization', value: ({ organization }) => organization?.name },
  // {
  //   key: 'permissions',
  //   label: 'Permissions',
  //   value: 'permissions',
  // },
];

const operations: TablePanel.Row.Operation<ApiKey>[] = [
  {
    label: 'View',
    icon: faEye,
    fn: (apiKey) => {
      void modals.developer.viewApiKey({ apiKey });
    },
  },
  // {
  //   label: 'Edit',
  //   icon: faEdit,
  //   fn: (apiKey) => { },
  // },
  {
    label: 'Delete',
    icon: faTrash,
    fn: (apiKey) => {
      void modals.confirm.deleteItems({ itemType: 'apiKeys', items: [apiKey] });
    },
    hidden: (apiKey) => {
      return apiKey.organization === null;
    },
  },
  {
    label: 'Revoke',
    icon: faBan,
    fn: async (apiKey) => {
      const options: api.developer.RevokeApiKeyOptions = {
        apiKey: apiKey.id,
        active: false,
      };
      
      try {
        await store.dispatch('apiKeys/revoke', options);
        alert.success('API key revoked successfully.');
      } catch (error) {
        alert.error('Failed to revoke API key.');
      }
    },
    variant: 'danger',
  }
];

@Module({ namespaced: true })
export class ApiKeyTablePanel
  extends TablePanel<ApiKey>
  implements TablePanel.Props<ApiKey> {
  readonly module = 'apiKeys';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'API KEYS';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'createApiKey';
    // readonly deleteAction = 'deleteAPIKey';
  readonly enabled = true;
  readonly tableSortType = 'createdAt';
  readonly tableSortReverse = false;
  readonly tablePageSize = 50;

  @Action
  async createApiKey() {
    await modals.developer.createApiKey();
  }
}

export namespace ApiKeyTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: ApiKey[];
  }
}
