<script lang="ts">
import { computed } from 'vue';
import LabelStatus from '@components/LabelStatus.vue';
import type { StatusConfig } from '@components/LabelStatus.vue';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellStatus: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellStatus' };
</script>

<script setup lang="ts">
export interface Props {
  value: string;
  statusConfigs?: StatusConfig;
  fullwidth?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  fullwidth: false,
});

const status = computed(() => props.value);
</script>

<template>
  <div class="table-cell-status">
    <LabelStatus
      :status="status"
      :status-configs="statusConfigs"
      :fullwidth="fullwidth"
    />
  </div>
</template>

<style scoped lang="scss">
.table-cell-status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}
</style> 
