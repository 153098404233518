var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("span", { class: _setup.classList, style: _setup.style }, [
    _vm._v("\n  " + _vm._s(_setup.displayConfig.text) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }