import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import find from 'lodash/find';

import { api } from '@api';
import { OfflineModeJob, Organization } from '@models';
import { Root } from '@store';

declare module '@vuex/core' {
  export interface Getters {
    'offlineModeJobs/findById': OfflineModeJobs['findById'];
  }

  export interface CommitMap {
    'offlineModeJobs/SET': OfflineModeJobs['SET'];
    'offlineModeJobs/CLEAR': OfflineModeJobs['CLEAR'];
  }

  export interface DispatchMap {
    'offlineModeJobs/list': OfflineModeJobs['list'];
  }
}

@Module({ namespaced: true })
export class OfflineModeJobs
  extends VuexModule<OfflineModeJobs.State, Root.State>
  implements OfflineModeJobs.State
{
  items: OfflineModeJob[] = [];
  lastEvaluatedKey: ZephyrWeb.Pagination.PlacementKey | null = null;
  allResultsLoaded = false;

  get findById() {
    return (id: string) => find(this.items, { id });
  }

  @Mutation
  SET(options: OfflineModeJobs.SetMutationOptions) {
    this.items = options.data;
  }

  @Mutation
  SET_LAST_EVALUATED_KEY(key: ZephyrWeb.Pagination.PlacementKey | null) {
    this.lastEvaluatedKey = key;
    this.allResultsLoaded = !key;
  }

  @Mutation
  CLEAR() {
    this.items = [];
    this.lastEvaluatedKey = null;
    this.allResultsLoaded = false;
  }

  @Action
  async loadPage(options?: { filter?: { params?: Record<string, unknown> } }) {
    const { me } = this.context.rootState;
    const organizationId = options?.filter?.params?.['organizationId'] || me.organization?.id;

    if (!organizationId) {
      return {
        items: [],
        lastEvaluatedKey: null
      };
    }

    const searchOptions: api.organizations.GetOfflineModeJobsOptions = {
      organizationId: organizationId as Organization['id'],
      limit: 25,
      order: 'DESC'
    };

    if (this.lastEvaluatedKey) {
      searchOptions.startKey = this.lastEvaluatedKey;
    }

    const { items, lastEvaluatedKey } = await api.organizations.getOfflineModeJobs(searchOptions);

    this.context.commit('SET', { data: items });
    this.context.commit('SET_LAST_EVALUATED_KEY', lastEvaluatedKey);

    return { items, lastEvaluatedKey };
  }

  @Action
  async list(options?: { filter?: { params?: Record<string, unknown> } }) {
    const { me } = this.context.rootState;
    const organizationId = options?.filter?.params?.['organizationId'] || me.organization?.id;

    if (!organizationId) {
      return [];
    }

    const res = await api.organizations.getOfflineModeJobs({
      organizationId: organizationId as Organization['id'],
      limit: 25,
      order: 'DESC'
    });

    const data = res.items ?? [];
    this.context.commit('SET', { data });

    return this.items;
  }
}

export namespace OfflineModeJobs {
  export interface State {
    items: OfflineModeJob[];
    lastEvaluatedKey: ZephyrWeb.Pagination.PlacementKey | null;
    allResultsLoaded: boolean;
  }

  export interface SetMutationOptions {
    data: OfflineModeJob[];
  }
}

export default OfflineModeJobs;
