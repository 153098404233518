/**
 * Global site tag (gtag.js) - Google Analytics
 */

const GA_MEASUREMENT_ID = 'G-M5SVVD11QX'; // cspell:ignore G-M5SVVD11QX

const gtagScript = document.createElement('script');
gtagScript.async = true;
gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;

const [scriptTag] = document.getElementsByTagName('script');
scriptTag.parentNode.insertBefore(gtagScript, scriptTag);

window.dataLayer = window.dataLayer || [];

function gtag() {
  window.dataLayer.push(arguments);
}

gtag('js', new Date());
gtag('config', GA_MEASUREMENT_ID);
